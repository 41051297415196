import React, { Component } from "react";
import ItemsTable from "../../components/ItemsTable";
import ApiRequests from "../../utils/ApiRequests";
import {
  CircularProgress,
  Modal,
  Typography,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import Header from "../../components/Header";
import UserDetailsModal from "../../components/UserDetailsModal";
import { RefreshOutlined } from "@material-ui/icons";

export default class Items extends Component {
  state = {
    loading: true,
    items: [],
    showModal: false,
    selectedUserId: null,
    newItemName: null,
    newItemPrice: null,
  };

  componentDidMount() {
    this.fetchItems();
  }

  fetchItems = async () => {
    this.setState({ loading: true });
    try {
      let data = await ApiRequests.get("items");
      this.setState({
        items: data["payload"],
        loading: false,
      });
    } catch (error) {
      alert("Something went wrong");
      this.setState({
        loading: false,
      });
    }
  };

  handleOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  addItem = async () => {
    if (this.state.newItemName !== null && this.state.newItemPrice !== null)
      try {
        let res = await ApiRequests.post("items", {
          name: this.state.newItemName,
          price: this.state.newItemPrice,
        });
        let updatedItems = [...this.state.items];
        updatedItems.push(res.payload);
        this.setState({
          items: updatedItems,
          newItemName: null,
          newItemPrice: null,
        });
        alert("Item added successfully!");
      } catch (error) {
        alert("Something went wrong");
      }
    else alert("Please enter all the details!");
  };

  deleteItem = async (id) => {
    if (window.confirm("Are you sure, you want to delete the selected item?"))
      try {
        let res = await ApiRequests.delete(`items/${id}`);
        let updatedItems = [...this.state.items];

        this.setState({
          items: updatedItems.filter((item) => item._id != id),
        });
        alert("Item deleted successfully!");
      } catch (error) {
        alert("Something went wrong");
      }
  };

  // selectUser = (id) => {
  //   console.log("incoming id", id);
  //   this.setState({
  //     selectedUserId: id,
  //     showModal: true,
  //   });
  //   console.log("set id", this.state.id);
  // };

  render() {
    if (this.state.loading)
      return (
        <div>
          <Header title={"Items"} />
          <center>
            <CircularProgress color="inherit" />
          </center>
        </div>
      );
    return (
      <div>
        <Header
          title={"Items"}
          icon={
            <RefreshOutlined
              style={{ marginLeft: "32px", verticalAlign: "middle" }}
              onClick={this.fetchItems}
            ></RefreshOutlined>
          }
        />
        <Modal open={this.state.showModal} onClose={this.handleClose}>
          <div style={{ margin: "64px" }}>
            <UserDetailsModal userId={this.state.selectedUserId} />
          </div>
        </Modal>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/* <Typography style={{ fontStyle: "italic" }}>
              Click on Item to delete
            </Typography> */}
            <ItemsTable
              list={this.state.items}
              onClick={(id) => {
                this.deleteItem(id);
              }}
              reload={this.fetchItems}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ fontStyle: "bold" }}>Add New Item</Typography>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="newItemName"
              label="Item's Name"
              name="newItemName"
              autoComplete="newItemName"
              value={this.state.newItemName}
              onChange={(e) => {
                this.setState({ newItemName: e.target.value });
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="newItemPrice"
              label="Item's Price"
              name="newItemPrice"
              autoComplete="newItemPrice"
              value={this.state.newItemPrice}
              onChange={(e) => {
                this.setState({ newItemPrice: e.target.value });
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) this.addItem();
              }}
            />
            <Button variant="contained" color="primary" onClick={this.addItem}>
              Add Item
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
