import React, { Component } from "react";
import {
    Grid,
    TextField,
    Button,
} from "@material-ui/core";
import ApiRequests from "../../../utils/ApiRequests";
import { Typography } from "@material-ui/core";
import Header from "../../../components/Header";
import ButtonComponent from './ButtonComponent.js';

export default class AddNewSale extends Component {
    state = {
        userId: null,
        packageId: null,
        setuserIdError: false,
        setpackageIdError: false,
        buttonShow: false,
    };

    addSale = async () => {
        let userId = this.state.userId;
        let packageId = this.state.packageId;
        if (!userId) {
            this.setState({
                setuserIdError: true,
            });
        }
        if (!packageId) {
            this.setState({
                setpackageIdError: true,
            });
        }

        try {
            if (userId && packageId) {
                this.setState({
                    buttonShow: true,
                });
                await ApiRequests.post("sales/sale/", {
                    ...this.state
                });
                this.setState({
                    buttonShow: false,
                });
                alert("Sale added successfully!");
                this.setState({
                    buttonShow: false,
                });
                window.location.reload();
            }
        } catch (error) {
            alert("Something went wrong");
            this.setState({
                buttonShow: false,
            });
            console.log(error);
        }
    };



    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        }, () => {
            this.setState({
                setuserIdError: false,
                setpackageIdError: false,
            })
        });
    };

    render() {
        return (
            <div>
                <Header title={"Add Sale"} />
                <Grid container spacing={12}>
                    {[
                        {
                            field: "userId",
                            label: "sale userId",
                            placeholder: "sale userId",
                            hint: this.state.setuserIdError ? "sale userId" : "",
                        },
                        {
                            field: "packageId",
                            label: "sale packageId",
                            placeholder: "sale packageId",
                            hint: this.state.setpackageIdError ? "sale packageId" : "",
                        },
                    ].map((element, index) => (
                        <Grid item xs={10}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id={element.field}
                                label={element.label}
                                name={element.field}
                                placeholder={element.placeholder || element.label}
                                autoComplete={element.field}
                                value={this.state[element.field]}
                                onChange={this.handleChange}
                                multiline={
                                    element.field == "msgBody" || element.field == "name"
                                        ? true
                                        : false
                                }
                            />
                            {element.hint && <Typography color="error">{element.hint}</Typography>}
                        </Grid>
                    ))}

                </Grid>
                <br />
                <br />
                <Button variant="contained" disabled={this.state.buttonShow || this.state.setuserIdError || this.state.setpackageIdError} color="primary" onClick={this.addSale}>
                    Add Sale
        </Button>
            </div >
        );
    }
}
