import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import ApiRequests from "../utils/ApiRequests";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Login extends Component {
  state = {
    email: "",
    password: "",
  };

  handleSignIn = async () => {
    let email = this.state.email;
    let password = this.state.password;
    if (!email || !password) {
      alert("Please enter the credentials!");
      return;
    }
    try {
      let data = await ApiRequests.post("auth/login", {
        email: email,
        password: password,
        userType: "admin",
      });

      // store token to localStorage
      localStorage.setItem("qat_token", data.payload.token);
      window.location.reload();
    } catch (error) {
      alert("Invalid credentials!");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            QAT App - Admin
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email Id"
              name="email"
              autoComplete="email"
              autoFocus
              value={this.state.email}
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) this.handleSignIn();
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={this.state.password}
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) this.handleSignIn();
              }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleSignIn}
            >
              Login
            </Button>
          </form>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Login);
