import React, { Component } from "react";
import PickupsTable from "../../components/PickupsTable";
import ApiRequests from "../../utils/ApiRequests";
import { CircularProgress, Modal, Typography } from "@material-ui/core";
import Header from "../../components/Header";
import PickupDetailsModal from "../../components/PickupDetailsModal";
import { RefreshOutlined } from "@material-ui/icons";

export default class Pickups extends Component {
  state = {
    loading: true,
    data: [],
    showModal: false,
    selectedPickupId: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true });
    try {
      let data = await ApiRequests.get("pickups");
      this.setState({
        data: data["payload"],
        loading: false,
      });
    } catch (error) {
      alert("Something went wrong");
      this.setState({
        loading: false,
      });
    }
  };

  handleOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  selectRequest = (id) => {
    this.setState({
      selectedPickupId: id,
      showModal: true,
    });
  };

  render() {
    if (this.state.loading)
      return (
        <div>
          <Header title={"Home Pickup Requests"} />
          <center>
            <CircularProgress color="inherit" />
          </center>
        </div>
      );
    return (
      <div>
        <Header
          title={"Home Pickup Requests"}
          icon={
            <RefreshOutlined
              style={{ marginLeft: "32px", verticalAlign: "middle" }}
              onClick={this.fetchData}
            ></RefreshOutlined>
          }
        />
        <Modal open={this.state.showModal} onClose={this.handleClose}>
          <div style={{ margin: "64px" }}>
            <PickupDetailsModal
              id={this.state.selectedPickupId}
              handleClose={this.handleClose}
            />
          </div>
        </Modal>
        <Typography style={{ fontStyle: "italic" }}>
          Click on Request to view Details
        </Typography>
        <PickupsTable
          list={this.state.data}
          onClick={(id) => {
            this.selectRequest(id);
          }}
        />
      </div>
    );
  }
}
