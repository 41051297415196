import React, { Component } from "react";
import {
    Grid,
    TextField,
    Button,
} from "@material-ui/core";
import ApiRequests from "../../../utils/ApiRequests";
import { CircularProgress, Typography } from "@material-ui/core";
import ButtonComponent from './ButtonComponent.js'

export default class UpdateSale extends Component {
    state = {
        userId: this.props.data.userId,
        packageId: this.props.data.packageId,
        setuserIdError: false,
        setpackageIdError: false,
    };

    UpdateSale = async (id, close) => {
        let userId = this.state.userId;
        let packageId = this.state.packageId;

        if (!userId) {
            this.setState({
                setuserIdError: true,
            });
        }
        if (!packageId) {
            this.setState({
                setpackageIdError: true,
            });
        }
        try {
            if (userId && packageId) {
                await ApiRequests.patch("sales/sale/" + id, {
                    ...this.state,
                });
                close();
                alert("Sale  updated successfully!");
            }
        } catch (error) {
            alert("Something went wrong");
            console.log(error);
            close();
        }

    };
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        }, () => {
            this.setState({
                setuserIdError: false,
                setpackageIdError: false,
            })
        });
    };
    render() {
        if (this.state.loading)
            return (
                <center>
                    <CircularProgress color="inherit" />
                </center>
            );
        return (
            <div>
                <Grid container spacing={12}>
                    {[
                        {
                            field: "userId",
                            label: "sale userId",
                            placeholder: "sale userId",
                            hint: this.state.setuserIdError ? "sale userId" : "",
                        },
                        {
                            field: "packageId",
                            label: "sale packageId",
                            placeholder: "sale packageId",
                            hint: this.state.setpackageIdError ? "sale packageId" : "",
                        },
                    ].map((element, index) => (
                        <Grid item xs={10}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id={element.field}
                                label={element.label}
                                name={element.field}
                                placeholder={element.placeholder || element.label}
                                autoComplete={element.field}
                                value={this.state[element.field]}
                                onChange={this.handleChange}
                                multiline={
                                    element.field == "msgBody" || element.field == "name"
                                        ? true
                                        : false
                                }
                            />
                            {element.hint && <Typography color="error">{element.hint}</Typography>}
                        </Grid>
                    ))}

                </Grid>
                <br />
                <br />
                <Button variant="contained" disabled={this.state.setuserIdError || this.state.setpackageIdError} color="primary" style={{ marginRight: "10px" }} onClick={() => {
                    this.UpdateSale(this.props.data._id, this.props.close)
                }}> Update Sale</Button>
                <Button variant="contained" onClick={this.props.close} color="inherit"> close  </Button>
            </div>
        );
    }
}
