import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { CheckBoxOutlined, CheckBoxOutlineBlank } from "@material-ui/icons";

import moment from "moment";
import ApiRequests from "../utils/ApiRequests";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(_id, name, email, phone, isPremium, createdAt, updatedAt) {
  return { _id, name, email, phone, isPremium, createdAt, updatedAt };
}

export default function UsersTable(props) {
  const classes = useStyles();

  const rows = [];

  props.list.forEach((element) =>
    rows.push(
      createData(
        element._id,
        element.name,
        element.email,
        element.phone,
        element.isPremium,
        element.createdAt,
        element.updatedAt
      )
    )
  );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email Id</TableCell>
            {/* <TableCell>Phone</TableCell> */}
            <TableCell>Premium</TableCell>
            <TableCell>Registered At</TableCell>
            <TableCell>Last Login At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row._id}
              onClick={() => {
                props.onClick(row._id);
              }}
              style={{ cursor: "pointer" }}
            >
              <TableCell component="th" scope="row">
                {row.name || "-"}
              </TableCell>
              <TableCell>{row.email}</TableCell>
              {/* <TableCell>{row.isPremium ? "Yes" : "No"}</TableCell> */}
              <TableCell>
                <IconButton
                  onClick={async () => {
                    try {
                      await ApiRequests.patch("users/" + row._id, {
                        isPremium: !row.isPremium,
                      });
                      alert("User's premium status updated!");
                      window.location.reload();
                    } catch (error) {
                      alert("Something went wrong!");
                    }
                  }}
                >
                  {row.isPremium ? (
                    <CheckBoxOutlined></CheckBoxOutlined>
                  ) : (
                    <CheckBoxOutlineBlank></CheckBoxOutlineBlank>
                  )}
                </IconButton>
              </TableCell>
              <TableCell>
                {moment(row.createdAt).format("MMMM Do, h:mm a")}
              </TableCell>
              <TableCell>
                {moment(row.updatedAt).format("MMMM Do, h:mm a")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
