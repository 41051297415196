import React, { Component } from "react";
import Header from "../../components/Header";
import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import ApiRequests from "../../utils/ApiRequests";

export default class Dashboard extends Component {
  state = {
    loading: true,
    total: 0,
    premium: 0,
    revenue: 0,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true });
    try {
      let data = await ApiRequests.get("data/admin/counts");
      this.setState({
        total: data["total"],
        premium: data["premium"],
        revenue: data["revenue"],
        loading: false,
      });
    } catch (error) {
      alert("Something went wrong");
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const cards = [
      {
        data: `${this.state.total}`,
        path: "/admin/users",
        header: <Typography variant="h5">Total Users</Typography>,
      },
      {
        data: `${this.state.premium}`,
        path: "/admin/users",
        header: <Typography variant="h5">Premium Users</Typography>,
      },
      {
        data: `$${this.state.revenue}`,
        path: "/admin/users",
        header: <Typography variant="h5">Total Revenue</Typography>,
      },
    ];
    if (this.state.loading)
      return (
        <div>
          <Header title={"Dashboard"} />
          <center>
            <CircularProgress color="inherit" />
          </center>
        </div>
      );
    return (
      <div>
        <Header title={"Dashboard"} />

        <Grid container spacing={3}>
          {cards.map((item, index) => (
            <Grid item xs={4}>
              <Link
                key={item.path}
                to={item.path}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Paper
                  style={{
                    height: 160,
                    padding: 32,
                  }}
                  elevation={3}
                >
                  <center>
                    {item.header}
                    <br />
                    <Typography variant="h6">{item.data}</Typography>
                  </center>
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}
