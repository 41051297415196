import React, { Component } from "react";
import Header from "../../components/Header";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import ApiRequests from "../../utils/ApiRequests";

export default class Notifications extends Component {
  state = {
    title: "",
    body: "",
  };

  sendNotification = async () => {
    try {
      await ApiRequests.post("data/notification", {
        topic: "all_users",
        title: this.state.title,
        body: this.state.body,
      });
      this.setState({
        title: "",
        body: "",
      });
      alert("Notification sent successfully");
    } catch (error) {
      alert("Something went wrong");
    }
  };

  render() {
    return (
      <div>
        <Header title={"Send Notification"} />
        <Typography style={{ fontStyle: "italic" }}>
          Sends notification to all users
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="title"
                label="Title"
                name="title"
                autoComplete="title"
                value={this.state.title}
                onChange={(e) => {
                  this.setState({ title: e.target.value });
                }}
              />
            </Grid>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              multiline
              rows={4}
              rowsMax={4}
              id="body"
              label="Body"
              name="body"
              autoComplete="body"
              value={this.state.body}
              onChange={(e) => {
                this.setState({ body: e.target.value });
              }}
            />
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              disabled={
                this.state.title === null ||
                this.state.title === "" ||
                this.state.body === null ||
                this.state.body === ""
              }
              onClick={this.sendNotification}
            >
              Send Notification
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
