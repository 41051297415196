import React, { Component } from "react";
import TranslateList from "./translateList";
import ApiRequests from "../../../utils/ApiRequests";
import { Button, CircularProgress, Modal, Typography } from "@material-ui/core";
import Header from "../../../components/Header";
import { RefreshOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";

export default class Translate extends Component {
    state = {
        loading: true,
        translotors: [],
    };

    componentDidMount() {
        this.fetchTranslators();
    }

    fetchTranslators = async () => {
        this.setState({ loading: true });
        try {
            let data = await ApiRequests.get("translations/translators");
            this.setState({
                translotors: data["payload"],
                loading: false,
            });
        } catch (error) {
            alert("Something went wrong");
            this.setState({
                loading: false,
            });
        }
    };

    handleOpen = () => {
        this.setState({
            showModal: true,
        });
    };

    handleClose = () => {
        this.setState({
            showModal: false,
        });
    };
    render() {
        if (this.state.loading)
            return (
                <div>
                    <Header title={"Translate"} />
                    <center>
                        <CircularProgress color="inherit" />
                    </center>
                </div>
            );
        return (
            <div>
                <Header
                    title={"Translate"}
                    icon={
                        <RefreshOutlined
                            style={{ marginLeft: "32px", verticalAlign: "middle" }}
                            onClick={this.fetchTranslators}
                        ></RefreshOutlined>
                    }
                />
                <Link
                    to="/admin/translate/add"
                    style={{ textDecoration: "none", color: "black" }}
                >
                    <Button variant="contained" color="primary">Add new Translator</Button>
                </Link>
                <br />
                <br />

                <TranslateList
                    list={this.state.translotors}
                    onClick={(id) => {
                        this.selectUser(id);
                    }}
                    reload={() => this.fetchTranslators()}
                />
            </div>
        );
    }
}
