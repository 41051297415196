import React, { Component } from "react";
import PackageList from "./packageList";
import ApiRequests from "../../../utils/ApiRequests";
import { Button, CircularProgress, Modal, Typography } from "@material-ui/core";
import Header from "../../../components/Header";
import { RefreshOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";

export default class Package extends Component {
    state = {
        loading: true,
        packages: [],
    };

    componentDidMount() {
        this.fetchPackages();
    }

    fetchPackages = async () => {
        this.setState({ loading: true });
        try {
            let data = await ApiRequests.get("packages/packages");
            this.setState({
                packages: data["payload"],
                loading: false,
            });
        } catch (error) {
            alert("Something went wrong");
            this.setState({
                loading: false,
            });
        }
    };

    handleOpen = () => {
        this.setState({
            showModal: true,
        });
    };

    handleClose = () => {
        this.setState({
            showModal: false,
        });
    };
    render() {
        if (this.state.loading)
            return (
                <div>
                    <Header title={"Package"} />
                    <center>
                        <CircularProgress color="inherit" />
                    </center>
                </div>
            );
        return (
            <div>
                <Header
                    title={"Package"}
                    icon={
                        <RefreshOutlined
                            style={{ marginLeft: "32px", verticalAlign: "middle" }}
                            onClick={this.fetchPackages}
                        ></RefreshOutlined>
                    }
                />
                <Link
                    to="/admin/package/add"
                    style={{ textDecoration: "none", color: "black" }}
                >
                    <Button variant="contained" color="primary">Add new Package</Button>
                </Link>
                <br />
                <br />

                <PackageList
                    list={this.state.packages}
                    onClick={(id) => {
                        this.selectUser(id);
                    }}
                    reload={() => this.fetchPackages()}
                />
            </div>
        );
    }
}
