import React, { Component } from "react";
import ApiRequests from "../utils/ApiRequests";
import {
  CircularProgress,
  Paper,
  Typography,
  Divider,
} from "@material-ui/core";
import { RefreshOutlined } from "@material-ui/icons";
import Header from "../components/Header";
import SearchesTable from "../components/SearchesTable";

export default class UserDetailsModal extends Component {
  state = {
    loading: true,
    searches: [],
  };

  componentDidMount() {
    this.fetchSearches();
  }

  fetchSearches = async () => {
    this.setState({ loading: true });
    try {
      let data = await ApiRequests.get(
        `users/${this.props.userId}/searches`
      );
      this.setState({
        searches: data["payload"],
        loading: false,
      });
    } catch (error) {
      alert("Something went wrong");
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    if (this.state.loading)
      return (
        <div>
          <Paper style={{ padding: "32px" }}>
            <Header title={`Recent Activity`} />
            <center>
              <CircularProgress color="inherit" />
            </center>
            <br />
            <br />
          </Paper>
        </div>
      );
    else if (this.state.searches.length === 0)
      return (
        <div>
          <Paper style={{ padding: "32px" }}>
            <Header
              title={`Recent Activity`}
              icon={
                <RefreshOutlined
                  style={{ marginLeft: "32px", verticalAlign: "middle" }}
                  onClick={this.fetchSearches}
                ></RefreshOutlined>
              }
            />
            <Typography variant="subtitle1">No activity found</Typography>
            <br />
            <Divider></Divider>
            <br />
            <br />
          </Paper>
        </div>
      );
    return (
      <div>
        <Paper style={{ padding: "32px" }}>
          <Header
            title={`Recent Activity`}
            icon={
              <RefreshOutlined
                style={{ marginLeft: "32px", verticalAlign: "middle" }}
                onClick={this.fetchSearches}
              ></RefreshOutlined>
            }
          />
          <Typography variant="subtitle1">
            {`${this.state.searches[0].user.email}`}
          </Typography>
          <Typography variant="subtitle1">
            {`${this.state.searches[0].user.name || ""}`}
          </Typography>
          <br />
          <Divider></Divider>
          <br />
          <br />
          {this.state.loading ? (
            <div>
              <center>
                <CircularProgress color="inherit" />
              </center>
            </div>
          ) : this.state.searches.length > 0 ? (
            <SearchesTable list={this.state.searches} />
          ) : (
            <div></div>
          )}
        </Paper>
      </div>
    );
  }
}
