import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none',
    },
}));

export default function UploadButtons(props) {
    const classes = useStyles();
    return (
        <div className={classes.root} style={{ marginTop: "18px" }}>
            <input
                accept="application/JSON"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                onChange={props.ClickButton}
            />
            <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                    Upload Json file Here
        </Button>
            </label>

        </div>
    );
}