import React, { Component } from "react";
import {
    Grid,
    TextField,
    Button,
} from "@material-ui/core";
import ApiRequests from "../../../utils/ApiRequests";
import { Typography } from "@material-ui/core";
import Header from "../../../components/Header";
import ButtonComponent from './ButtonComponent'
export default class AddNewTranslate extends Component {
    state = {
        language: null,
        translator: null,
        flag: null,
        translation_file: null,
        settranslatorError: false,
        setLanguageError: false,
        setflagError: false,
        setFileNameError: false,
        buttonShow: false,
    };

    addTranslator = async () => {
        let language = this.state.language;
        let flag = this.state.flag;
        let translator = this.state.translator;
        let translation_file = this.state.translation_file;
        if (!translator) {
            this.setState({
                settranslatorError: true,
            });
        }
        if (!language) {
            this.setState({
                setLanguageError: true,
            });
        }
        if (!flag) {
            this.setState({
                setflagError: true,
            });
        }
        if (!translation_file) {
            this.setState({
                setFileNameError: true,
            });
        }

        try {
            if (language && flag && translator && translation_file) {
                this.setState({
                    buttonShow: true,
                });
                await ApiRequests.post("translations/translator/", {
                    ...this.state
                });
                this.setState({
                    buttonShow: false,
                });
                alert("Translator added successfully!");
                this.setState({
                    buttonShow: false,
                });
                window.location.reload();
            }
        } catch (error) {
            alert("Something went wrong");
            this.setState({
                buttonShow: false,
            });
            console.log(error);
        }
    };



    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        }, () => {
            this.setState({
                setflagError: false,
                settranslatorError: false,
                setLanguageError: false,
                setFileNameError: false
            })
        });
    };

    render() {
        return (
            <div>
                <Header title={"Add Translator"} />
                <Grid container spacing={12}>
                    {[
                        { field: "translator", label: "Translator ( name)", hint: this.state.settranslatorError ? "Enter Translator Name " : "" },
                        { field: "language", label: "Language", placeholder: "Arabic", hint: this.state.setLanguageError ? "Enter Language Name " : "" },
                        {
                            field: "flag",
                            label: "Photo File Name",
                            placeholder: "Name of image in the folder: ~/app/data/flags/",
                            hint: this.state.setflagError ? "Enter Profile pic  Name " : "",
                        },
                        {
                            field: "translation_file",
                            label: " Json File Name",
                            placeholder: "Name of image in the folder: ~/app/data/translations/",
                            hint: this.state.setFileNameError ? "Enter Json  File  Name " : "",
                        },
                    ].map((element, index) => (
                        <Grid item xs={10}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id={element.field}
                                label={element.label}
                                name={element.field}
                                placeholder={element.placeholder || element.label}
                                autoComplete={element.field}
                                value={this.state[element.field]}
                                onChange={this.handleChange}
                                multiline={
                                    element.field == "msgBody" || element.field == "name"
                                        ? true
                                        : false
                                }
                            />
                            {element.hint && <Typography color="error">{element.hint}</Typography>}
                        </Grid>
                    ))}

                </Grid>
                <br />
                <br />
                <Button variant="contained" disabled={this.state.setLanguageError || this.state.buttonShow || this.state.setflagError || this.state.settranslatorError} color="primary" onClick={this.addTranslator}>
                    Add Translator
        </Button>
            </div >
        );
    }
}
