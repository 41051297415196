import React, { Component } from "react";
import { Grid, TextField, Button, Divider, FormGroup, FormControlLabel, Switch } from "@material-ui/core";
import ApiRequests from "../../utils/ApiRequests";
import { CircularProgress, Typography } from "@material-ui/core";
import Header from "../../components/Header";

export default class AppSettings extends Component {
  state = {
    loading: true,
    appVersion: null,
    appMode: null,
    msgWelcome1: null,
    msgPremium1: null,
    msgOther1: null,
    msgWelcome2: null,
    msgPremium2: null,
    msgOther2: null,
    msgDuaQiblaPrayer_dua: null,
    msgDuaQiblaPrayer_qibla: null,
    msgDuaQiblaPrayer_prayer: null,
    msgQuran99namesLearnArabic_quran: null,
    msgQuran99namesLearnArabic_99names: null,
    msgQuran99namesLearnArabic_learnArabic: null,
  };

  componentDidMount() {
    this.fetchAppData();
  }

  fetchAppData = async () => {
    try {
      let data = await ApiRequests.get("data/app-settings");
      this.setState({
        loading: false,
        ...data.payload,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
      alert("Something went wrong");
    }
  };

  updateSettings = async () => {
    if (this.state.appVersion !== this.state.appVersionText)
      try {
        await ApiRequests.patch("data/app-settings", {
          ...this.state,
        });
        alert("App Settings updated successfully!");
        localStorage.setItem('api_mode', this.state.appMode);
      } catch (error) {
        alert("Something went wrong");
      }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeAppMode = (e) => {
    this.setState({
      [e.target.name]: e.target.checked? "dev": "prod",
    });
  };

  render() {
    if (this.state.loading)
      return (
        <center>
          <CircularProgress color="inherit" />
        </center>
      );
    else if (!this.state.appVersion)
      return (
        <center>
          <Typography>Something went wrong</Typography>
        </center>
      );
    return (
      <div>
        <Header title={"App Settings"} />

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="appVersion"
              label="Latest App Version"
              name="appVersion"
              autoComplete="appVersion"
              value={this.state.appVersion}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={4} style={{ display: "flex" }} alignItems="center">
            <FormGroup row>
              <FormControlLabel
                control={<Switch checked={this.state.appMode != "prod"} id="appMode" name="appMode" onChange={this.handleChangeAppMode} />}
                label="App Development Mode (Testing API)"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {[
            { field: "msgWelcome", label: "Welcome Message" },
            { field: "msgPremium", label: "Premium Content Message" },
            { field: "msgOther", label: "Premium Features" },
            { field: "msgDuaQiblaPrayer", label: "Dua'as, Qibla and Prayer Times Messages" },
            { field: "msgQuran99namesLearnArabic", label: "Quran, 99 names and learn Arabic Messages" },
            // { field: "name", label: "Description (below name)" },
            // { field: "language", label: "Language", placeholder: "Arabic" },
            // {
            //   field: "dirName",
            //   label: "Audios Folder Name",
            //   hint: "Name of the folder in: ~/public_ftp/qat/readers/",
            //   placeholder: "omar_jabbie",
            // },
            // {
            //   field: "photoUrl",
            //   label: "Photo File Name",
            //   hint: "Name of image in the folder: ~/app/data/reader_photos/",
            //   placeholder: "omar_jabbie",
            // },
            // { field: "mode", label: "Mode", placeholder: "1" },
          ].map((element, index) => (
            <Grid item xs={12}>
              <Grid item xs={4}>
                <Typography variant="h6">{element.label}</Typography>
                <Divider></Divider>
                {(element.field != "msgOther" && element.field != "msgDuaQiblaPrayer") && element.field != "msgQuran99namesLearnArabic" && (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id={`${element.field}1`}
                    label={`${element.label} (Heading)`}
                    name={`${element.field}1`}
                    placeholder="Heading"
                    value={this.state[`${element.field}1`]}
                    onChange={this.handleChange}
                  />
                )}
                {element.hint && <small>{element.hint}</small>}
              </Grid>
              {(element.field != "msgDuaQiblaPrayer") && element.field != "msgQuran99namesLearnArabic" && (
                <Grid item xs={8}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id={`${element.field}2`}
                    label={`${element.label} (Body)`}
                    name={`${element.field}2`}
                    placeholder="Body"
                    value={this.state[`${element.field}2`]}
                    onChange={this.handleChange}
                    multiline={true}
                  />
                  {element.hint && <small>{element.hint}</small>}
                </Grid>
              )}
              {(element.field != "msgWelcome" && element.field != "msgPremium" && element.field != "msgOther") && element.field != "msgQuran99namesLearnArabic" && (
                [
                  {id: "dua", title: "Dua'as Message"},
                  {id: "qibla", title: "Qibla Message"},
                  {id: "prayer", title: "Prayer Times message"},
                ].map((sub_element, sub_index) => (
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id={`${element.field}_${sub_element.id}`}
                      label={`${sub_element.title}`}
                      name={`${element.field}_${sub_element.id}`}
                      placeholder={`${sub_element.title}`}
                      value={this.state[`${element.field}_${sub_element.id}`]}
                      onChange={this.handleChange}
                      multiline={true}
                    />
                    {element.hint && <small>{element.hint}</small>}
                  </Grid>
                ))
              )}
              {(element.field != "msgWelcome" && element.field != "msgPremium" && element.field != "msgOther") && element.field != "msgDuaQiblaPrayer" && (
                [
                  {id: "quran", title: "Quran Message"},
                  {id: "99names", title: "99 Names Message"},
                  {id: "learnArabic", title: "Learn Arabic Message"},
                ].map((sub_element, sub_index) => (
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id={`${element.field}_${sub_element.id}`}
                      label={`${sub_element.title}`}
                      name={`${element.field}_${sub_element.id}`}
                      placeholder={`${sub_element.title}`}
                      value={this.state[`${element.field}_${sub_element.id}`]}
                      onChange={this.handleChange}
                      multiline={true}
                    />
                    {element.hint && <small>{element.hint}</small>}
                  </Grid>
                ))
              )}
            </Grid>
          ))}
        </Grid>
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={this.updateSettings}
        >
          Save
        </Button>
      </div>
    );
  }
}
