import React, { Component } from "react";
import ApiRequests from "../../utils/ApiRequests";
import {
  CircularProgress,
  Modal,
  Typography,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import Header from "../../components/Header";
import UserDetailsModal from "../../components/UserDetailsModal";
import { RefreshOutlined } from "@material-ui/icons";
import SitesTable from "../../components/SitesTable";
import moment from "moment";

export default class DropOffSites extends Component {
  state = {
    loading: true,
    items: [],
    timingsDocUrl: "",
    showModal: false,
    selectedUserId: null,
    newSiteName: null,
    newSiteCity: null,
    newSiteAddress: null,
    newSitePhone: null,
    newSiteOpeningHoursStart: "09:00",
    newSiteOpeningHoursEnd: "17:00",
  };

  componentDidMount() {
    this.fetchSites();
  }

  fetchSites = async () => {
    this.setState({ loading: true });
    try {
      let data = await ApiRequests.get("drop-off-sites");
      this.setState({
        items: data["payload"]["sites"],
        timingsDocUrl: data["payload"]["timingsDocUrl"],
        loading: false,
      });
    } catch (error) {
      alert("Something went wrong");
      this.setState({
        loading: false,
      });
    }
  };

  handleOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  addItem = async () => {
    if (this.state.newSiteName !== null && this.state.newSiteCity !== null)
      try {
        let res = await ApiRequests.post("drop-off-sites", {
          name: this.state.newSiteName,
          city: this.state.newSiteCity,
          address: this.state.newSiteAddress,
          phone: this.state.newSitePhone,
          openingHours: `${moment(
            `2020-01-01 ${this.state.newSiteOpeningHoursStart}`
          ).format("h:mm A")} - ${moment(
            `2020-01-01 ${this.state.newSiteOpeningHoursEnd}`
          ).format("h:mm A")}`,
        });
        let updatedItems = [...this.state.items];
        updatedItems.push(res.payload);
        this.setState({
          items: updatedItems,
          newSiteName: null,
          newSiteCity: null,
          newSiteAddress: null,
          newSitePhone: null,
        });
        alert("Site added successfully!");
      } catch (error) {
        alert("Something went wrong");
      }
    else alert("Please enter all the details!");
  };

  deleteItem = async (id) => {
    if (
      window.confirm(
        "Are you sure, you want to delete the selected Drop Off Site?"
      )
    )
      try {
        let res = await ApiRequests.delete(`drop-off-sites/${id}`);
        let updatedItems = [...this.state.items];

        this.setState({
          items: updatedItems.filter((item) => item._id != id),
        });
        alert("Site deleted successfully!");
      } catch (error) {
        alert("Something went wrong");
      }
  };

  updateTimingsDoc = async () => {
    try {
      await ApiRequests.patch(`misc/timingsDocUrl`, {
        timingsDocUrl: this.state.timingsDocUrl,
      });
      alert("Timings Document URL updated successfully!");
    } catch (error) {
      alert("Something went wrong");
    }
  };

  // selectUser = (id) => {
  //   console.log("incoming id", id);
  //   this.setState({
  //     selectedUserId: id,
  //     showModal: true,
  //   });
  //   console.log("set id", this.state.id);
  // };

  render() {
    if (this.state.loading)
      return (
        <div>
          <Header title={"Drop Off Sites"} />
          <center>
            <CircularProgress color="inherit" />
          </center>
        </div>
      );
    return (
      <div>
        <Header
          title={"Drop Off Sites"}
          icon={
            <RefreshOutlined
              style={{ marginLeft: "32px", verticalAlign: "middle" }}
              onClick={this.fetchSites}
            ></RefreshOutlined>
          }
        />
        {/* <Modal open={this.state.showModal} onClose={this.handleClose}>
          <div style={{ margin: "64px" }}>
            <UserDetailsModal userId={this.state.selectedUserId} />
          </div>
        </Modal> */}
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography style={{ fontStyle: "italic" }}>
              Click on Site to delete
            </Typography>
            <SitesTable
              list={this.state.items}
              onClick={(id) => {
                this.deleteItem(id);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ fontStyle: "bold" }}>Add New Site</Typography>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="newSiteName"
              label="Name"
              name="newSiteName"
              autoComplete="newSiteName"
              value={this.state.newSiteName}
              onChange={(e) => {
                this.setState({ newSiteName: e.target.value });
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="newSiteCity"
              label="City"
              name="newSiteCity"
              autoComplete="newSiteCity"
              value={this.state.newSiteCity}
              onChange={(e) => {
                this.setState({ newSiteCity: e.target.value });
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="newSiteAddress"
              label="Address"
              name="newSiteAddress"
              autoComplete="newSiteAddress"
              value={this.state.newSiteAddress}
              onChange={(e) => {
                this.setState({ newSiteAddress: e.target.value });
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="newSitePhone"
              label="Phone Number"
              name="newSitePhone"
              autoComplete="newSitePhone"
              value={this.state.newSitePhone}
              onChange={(e) => {
                this.setState({ newSitePhone: e.target.value });
              }}
              // onKeyDown={(e) => {
              //   if (e.keyCode === 13) this.addItem();
              // }}
            />
            {/* Opening Hours <br />
            <TextField
              id="arrival-time"
              // label="Opening Hours"
              type="time"
              value={this.state.newSiteOpeningHoursStart}
              onChange={(e) => {
                console.log(e.target.value);
                this.setState({ newSiteOpeningHoursStart: e.target.value });
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 600, // 5 min
              }}
            />
            {"  _  "}
            <TextField
              id="arrival-time"
              // label="Opening Hours"
              type="time"
              value={this.state.newSiteOpeningHoursEnd}
              onChange={(e) => {
                this.setState({ newSiteOpeningHoursEnd: e.target.value });
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 600, // 5 min
              }}
            />
            <br />
            <br /> */}
            <Button variant="contained" color="primary" onClick={this.addItem}>
              Add Site
            </Button>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="timingsDocUrl"
              label="Timings Document URL"
              name="timingsDocUrl"
              autoComplete="timingsDocUrl"
              value={this.state.timingsDocUrl}
              onChange={(e) => {
                this.setState({ timingsDocUrl: e.target.value });
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={this.updateTimingsDoc}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
