import React, { Component } from "react";
import {
    Grid,
    TextField,
    Button,
} from "@material-ui/core";
import ApiRequests from "../../../utils/ApiRequests";
import { Typography } from "@material-ui/core";
import Header from "../../../components/Header";
import ButtonComponent from './ButtonComponent.js';

export default class AddNewPackage extends Component {
    state = {
        slug: null,
        title: null,
        description: null,
        price: null,
        setslugError: false,
        settitleError: false,
        setdescriptionError: false,
        setpriceError: false,
        buttonShow: false,
    };

    addPackage = async () => {
        let slug = this.state.slug;
        let title = this.state.title;
        let description = this.state.description;
        let price = this.state.price;
        if (!slug) {
            this.setState({
                setslugError: true,
            });
        }
        if (!title) {
            this.setState({
                settitleError: true,
            });
        }
        if (!description) {
            this.setState({
                setdescriptionError: true,
            });
        }

        try {
            if (slug && title && description) {
                this.setState({
                    buttonShow: true,
                });
                await ApiRequests.post("packages/package/", {
                    ...this.state
                });
                this.setState({
                    buttonShow: false,
                });
                alert("Package added successfully!");
                this.setState({
                    buttonShow: false,
                });
                window.location.reload();
            }
        } catch (error) {
            alert("Something went wrong");
            this.setState({
                buttonShow: false,
            });
            console.log(error);
        }
    };



    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        }, () => {
            this.setState({
                setslugError: false,
                settitleError: false,
                setdescriptionError: false,
                setpriceError: false
            })
        });
    };

    render() {
        return (
            <div>
                <Header title={"Add Package"} />
                <Grid container spacing={12}>
                    {[
                        {
                            field: "slug",
                            label: "package slug",
                            placeholder: "package slug",
                            hint: this.state.setslugError ? "package slug" : "",
                        },
                        {
                            field: "title",
                            label: "package title",
                            placeholder: "package title",
                            hint: this.state.settitleError ? "package title" : "",
                        },
                        {
                            field: "description",
                            label: "package description",
                            placeholder: "package description",
                            hint: this.state.setdescriptionError ? "package description" : "",
                        },
                        {
                            field: "price",
                            label: "package price",
                            placeholder: "package price",
                            hint: this.state.setpriceError ? "package price" : "",
                        },
                    ].map((element, index) => (
                        <Grid item xs={10}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id={element.field}
                                label={element.label}
                                name={element.field}
                                placeholder={element.placeholder || element.label}
                                autoComplete={element.field}
                                value={this.state[element.field]}
                                onChange={this.handleChange}
                                multiline={
                                    element.field == "msgBody" || element.field == "name"
                                        ? true
                                        : false
                                }
                            />
                            {element.hint && <Typography color="error">{element.hint}</Typography>}
                        </Grid>
                    ))}

                </Grid>
                <br />
                <br />
                <Button variant="contained" disabled={this.state.buttonShow || this.state.settitleError || this.state.setdescriptionError} color="primary" onClick={this.addPackage}>
                    Add Package
        </Button>
            </div >
        );
    }
}
