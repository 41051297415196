import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Delete, Pause, PlayArrow, Edit } from "@material-ui/icons";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, CircularProgress, Modal, Typography } from "@material-ui/core";
import moment from "moment";
import UpdateReader from './updateTranslator'
import { IconButton } from "@material-ui/core";
import ApiRequests from "../../../utils/ApiRequests";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    paper: {
        position: 'absolute',
        width: 500,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        padding: theme.spacing(2, 4, 3),
    },
}));
function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
function createData(
    _id,
    flag,
    language,
    translator,
    translation_file,
    createdAt,
    updatedAt
) {
    return {
        _id,
        flag,
        language,
        translator,
        translation_file,
        createdAt,
        updatedAt,
    };
}

export default function TranslateList(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [userid, setuserid] = React.useState("");
    const [openModal, setOpenModal] = React.useState(false);
    const [data, setdata] = React.useState([]);
    const [updatedata, setupdatedata] = React.useState([]);
    const [modalStyle] = React.useState(getModalStyle);
    const rows = [];
    console.log(props.list)
    if (props && props.list && props.list.translators) {
        if (props.list.translators.length) {
            props.list.translators.forEach((element) =>
                rows.push(
                    createData(
                        element._id,
                        element.flag,
                        element.language,
                        element.translator,
                        element.translation_file,
                        element.createdAt,
                    )
                )
            );
        }
    }
    const handleCloseModal = () => {
        props.reload();
        setOpenModal(false);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenDelete = (id) => {
        setOpen(true);
        setuserid(id)
    };
    const handleClickOpenUpdate = (row) => {
        setOpenModal(true)
        setupdatedata(row)
    };
    const handleCloseDelete = async (id) => {
        try {
            await ApiRequests.delete("translations/translator/" + userid);
            setuserid("")
            setOpen(false);
            alert("Translator deleted!");
            props.reload();
        } catch (error) {
            setOpen(false);
            alert("Something went wrong!");
            setuserid("")
        }
    };

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Translator</TableCell>
                        <TableCell>flag</TableCell>
                        <TableCell>Language</TableCell>
                        <TableCell>Translate File Name</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row._id}>
                            <TableCell component="th" scope="row">
                                {row.translator || "-"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.flag || "-"}
                            </TableCell>
                            <TableCell>{row.language}</TableCell>
                            <TableCell component="th" scope="row">
                                {row.translation_file}
                            </TableCell>
                            <TableCell>
                                {moment(row.createdAt).format("MMMM Do, h:mm a")}
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => { handleClickOpenDelete(row._id) }}>
                                    <Delete ></Delete>
                                </IconButton>
                                <IconButton onClick={() => { handleClickOpenUpdate(row) }}>
                                    <Edit></Edit>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    <h2 id="simple-modal-title">Update Translator</h2>
                    <UpdateReader close={handleCloseModal} data={updatedata} />
                </div>
            </Modal>
            <Dialog
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete User"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure to delete this ??
                     </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary"> Cancle</Button>
                    <Button onClick={handleCloseDelete} color="primary" autoFocus> Delete</Button>
                </DialogActions>
            </Dialog>
        </TableContainer>
    );
}
