import React, { Component } from "react";
import {
    Grid,
    TextField,
    Button,
} from "@material-ui/core";
import ApiRequests from "../../../utils/ApiRequests";
import { CircularProgress, Typography } from "@material-ui/core";
import ButtonComponent from './ButtonComponent'

export default class UpdateTranslator extends Component {
    state = {
        language: this.props.data.language,
        translator: this.props.data.translator,
        flag: this.props.data.flag,
        translation_file: this.props.data.translation_file,
        setFileNameError: false,
        settranslatorError: false,
        setLanguageError: false,
        setflagError: false
    };

    UpdateTranslator = async (id, close) => {
        let language = this.state.language;
        let flag = this.state.flag;
        let translator = this.state.translator;
        let translation_file = this.state.translation_file;

        if (!translator || translator === undefined) {
            this.setState({
                settranslatorError: true,
            });
        }
        if (!language) {
            this.setState({
                setLanguageError: true,
            });
        }
        if (!flag) {
            this.setState({
                setflagError: true,
            });
        }
        if (!translation_file) {
            this.setState({
                setFileNameError: true,
            });
        }
        try {
            if (language && flag && translator && translation_file) {
                await ApiRequests.patch("translations/translator/" + id, {
                    ...this.state,
                });
                close();
                alert("Translator  updated successfully!");
            }
        } catch (error) {
            alert("Something went wrong");
            console.log(error);
            close();
        }

    };
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        }, () => {
            this.setState({
                setflagError: false,
                settranslatorError: false,
                setLanguageError: false,
                setFileNameError: false
            })
        });
    };
    render() {
        if (this.state.loading)
            return (
                <center>
                    <CircularProgress color="inherit" />
                </center>
            );
        return (
            <div>
                <Grid container spacing={12}>
                    {[
                        { field: "translator", label: "Translator ( name)", hint: this.state.settranslatorError ? "Enter Translator Name " : "" },
                        { field: "language", label: "Language", placeholder: "Arabic", hint: this.state.setLanguageError ? "Enter Language Name " : "" },
                        {
                            field: "flag",
                            label: "Photo File Name",
                            placeholder: "Name of image in the folder: ~/app/data/flags/",
                            hint: this.state.setflagError ? "Enter Profile pic  Name " : "",
                        },
                        {
                            field: "translation_file",
                            label: " Json File Name",
                            placeholder: "Name of image in the folder: ~/app/data/translations/",
                            hint: this.state.setFileNameError ? "Enter Json  File  Name " : "",
                        },

                    ].map((element, index) => (
                        <Grid item xs={10}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id={element.field}
                                label={element.label}
                                name={element.field}
                                placeholder={element.placeholder || element.label}
                                autoComplete={element.field}
                                value={this.state[element.field]}
                                onChange={this.handleChange}
                                multiline={
                                    element.field == "msgBody" || element.field == "name"
                                        ? true
                                        : false
                                }
                            />
                            {element.hint && <Typography color="error">{element.hint}</Typography>}
                        </Grid>
                    ))}

                </Grid>
                <br />
                <br />
                <Button variant="contained" disabled={this.state.setLanguageError || this.state.setFileNameError || this.state.setflagError || this.state.settranslatorError} color="primary" style={{ marginRight: "10px" }} onClick={() => {
                    this.UpdateTranslator(this.props.data._id, this.props.close)
                }}> Update Translator</Button>
                <Button variant="contained" onClick={this.props.close} color="inherit"> close  </Button>
            </div>
        );
    }
}
