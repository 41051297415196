import React, { Component } from "react";
import SaleList from "./saleList";
import ApiRequests from "../../../utils/ApiRequests";
import { Button, CircularProgress, Modal, Typography } from "@material-ui/core";
import Header from "../../../components/Header";
import { RefreshOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";

export default class Sale extends Component {
    state = {
        loading: true,
        sales: [],
    };

    componentDidMount() {
        this.fetchSales();
    }

    fetchSales = async () => {
        this.setState({ loading: true });
        try {
            let data = await ApiRequests.get("sales/sales");
            this.setState({
                sales: data["payload"],
                loading: false,
            });
        } catch (error) {
            alert("Something went wrong");
            this.setState({
                loading: false,
            });
        }
    };

    handleOpen = () => {
        this.setState({
            showModal: true,
        });
    };

    handleClose = () => {
        this.setState({
            showModal: false,
        });
    };
    render() {
        if (this.state.loading)
            return (
                <div>
                    <Header title={"Sale"} />
                    <center>
                        <CircularProgress color="inherit" />
                    </center>
                </div>
            );
        return (
            <div>
                <Header
                    title={"Sale"}
                    icon={
                        <RefreshOutlined
                            style={{ marginLeft: "32px", verticalAlign: "middle" }}
                            onClick={this.fetchSales}
                        ></RefreshOutlined>
                    }
                />
                <Link
                    to="/admin/sale/add"
                    style={{ textDecoration: "none", color: "black" }}
                >
                    <Button variant="contained" color="primary">Add new Sale</Button>
                </Link>
                <br />
                <br />

                <SaleList
                    list={this.state.sales}
                    onClick={(id) => {
                        this.selectUser(id);
                    }}
                    reload={() => this.fetchSales()}
                />
            </div>
        );
    }
}
