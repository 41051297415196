import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import TranslateIcon from '@material-ui/icons/Translate';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import MessageIcon from "@material-ui/icons/Message";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ReceiptIcon from '@material-ui/icons/Receipt';
import LogoutIcon from "@material-ui/icons/ExitToApp";
import {
  Event,
  LocationOn,
  Computer,
  Money,
  AttachMoney,
} from "@material-ui/icons";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Dashboard from "./tabs/Dashboard";
import Users from "./tabs/Users";
import AppSettings from "./tabs/AppSettings";
import Notifications from "./tabs/Notifications";
import Items from "./tabs/Items";
import DropOffSites from "./tabs/DropOffSites";
import Events from "./tabs/Events";
import Pickups from "./tabs/Pickups";
import QuoteRequests from "./tabs/QuoteRequests";
import EventRegistrations from "./tabs/EventRegistrations";
import Dummy from "./tabs/Dummy";
import Readers from "./tabs/Readers";
import AddReader from "./tabs/AddReader";
import UpdateReader from "./tabs/UpdateReader";
import Translate from './tabs/Translate/translate'
import Package from './tabs/Package/package'
import Sale from './tabs/Sale/sale'
import Addnewtranslate from './tabs/Translate/addNewTranslate'
import Addnewpackage from './tabs/Package/addNewPackage'
import Addnewsale from './tabs/Sale/addNewSale'
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Home() {
  const classes = useStyles();

  const tabs = [
    { title: "Dashboard", path: "/admin", icon: <DashboardIcon /> },
    { title: "Users", path: "/admin/users", icon: <PeopleIcon /> },
    // {
    //   title: "Fees Management",
    //   path: "/admin/fees-management",
    //   icon: <AttachMoney />,
    // },
    {
      title: "Push Notifications",
      path: "/admin/notifications",
      icon: <NotificationsIcon />,
    },
    {
      title: "Readers Management",
      path: "/admin/readers-management",
      icon: <PeopleIcon />,
    },
    {
      title: "Translators",
      path: "/admin/translate",
      icon: <TranslateIcon />,
    },
    {
      title: "Packages",
      path: "/admin/package",
      icon: <ListAltIcon />,
    },
    {
      title: "Sales",
      path: "/admin/sale",
      icon: <ReceiptIcon />,
    },
    {
      title: "App Settings",
      path: "/admin/app-settings",
      icon: <SettingsIcon />,
    },
    // { title: "Messages", path: "/admin/messages", icon: <MessageIcon /> },
  ];

  return (
    <div className={classes.root}>
      <Router>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classes.appBar}
          style={{ background: "#9C1038" }}
        >
          <Toolbar>
            <Typography variant="h5" noWrap className={classes.title}>
              QAT App
            </Typography>
            <IconButton
              edge="end"
              onClick={() => {
                localStorage.removeItem("qat_token");
                window.location.replace("/admin");
              }}
              color="inherit"
            >
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List>
              {tabs.map((item, index) => (
                <Link
                  key={item.path}
                  to={item.path}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button key={item.title}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItem>
                </Link>
              ))}
            </List>
            <Divider />
            <List>
              <ListItem
                button
                onClick={() => {
                  localStorage.removeItem("qat_token");
                  window.location.replace("/admin");
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </div>
        </Drawer>
        <main className={classes.content}>
          <Toolbar />
          <Switch>
            <Route path="/admin" exact={true}>
              <Dashboard />
            </Route>
            <Route path="/admin/users">
              {/* <Dummy title="Users" /> */}
              <Users />
            </Route>
            {/* <Route path="/admin/fees-management" exact={true}>
              <Dummy title="Fees Management" />
            </Route> */}
            <Route path="/admin/notifications">
              <Notifications />
              {/* <Dummy title="Notifications" /> */}
            </Route>
            <Route path="/admin/readers-management" exact>
              <Readers />
              {/* <Dummy title="Readers Management" /> */}
            </Route>

            <Route path="/admin/translate" exact>
              <Translate />
              {/* <Dummy title="Readers Management" /> */}
            </Route>
            <Route path="/admin/translate/add" exact>
              <Addnewtranslate />
              {/* <Dummy title="Readers Management" /> */}
            </Route>

            <Route path="/admin/package" exact>
              <Package />
              {/* <Dummy title="Readers Management" /> */}
            </Route>
            <Route path="/admin/package/add" exact>
              <Addnewpackage />
              {/* <Dummy title="Readers Management" /> */}
            </Route>

            <Route path="/admin/sale" exact>
              <Sale />
              {/* <Dummy title="Readers Management" /> */}
            </Route>
            <Route path="/admin/sale/add" exact>
              <Addnewsale />
              {/* <Dummy title="Readers Management" /> */}
            </Route>

            <Route path="/admin/readers-management/add" exact>
              <AddReader />
              {/* <Dummy title="Readers Management" /> */}
            </Route>

            <Route path="/admin/readers-management/:id" exact>
              <UpdateReader />
              {/* <Dummy title="Readers Management" /> */}
            </Route>

            <Route path="/admin/app-settings">
              <AppSettings />
              {/* <Dummy title="App Update" /> */}
            </Route>
            {/* <Route path="/admin/messages">
              <Dummy title="Messages" />
            </Route> */}
          </Switch>
        </main>
      </Router>
    </div>
  );
}
