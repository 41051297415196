import React, { Component } from "react";
import {
    Grid,
    TextField,
    Button,
} from "@material-ui/core";
import ApiRequests from "../../../utils/ApiRequests";
import { CircularProgress, Typography } from "@material-ui/core";
import ButtonComponent from './ButtonComponent.js'

export default class UpdatePackage extends Component {
    state = {
        slug: this.props.data.slug,
        title: this.props.data.title,
        description: this.props.data.description,
        price: this.props.data.price,
        settitleError: false,
        setdescriptionError: false,
    };

    UpdatePackage = async (id, close) => {
        let slug = this.state.slug;
        let title = this.state.title;
        let description = this.state.description;
        let price = this.state.price;

        if (!slug) {
            this.setState({
                setslugError: true,
            });
        }
        if (!title) {
            this.setState({
                settitleError: true,
            });
        }
        if (!description) {
            this.setState({
                setdescriptionError: true,
            });
        }
        try {
            if (slug && title && description) {
                await ApiRequests.patch("packages/package/" + id, {
                    ...this.state,
                });
                close();
                alert("Package  updated successfully!");
            }
        } catch (error) {
            alert("Something went wrong");
            console.log(error);
            close();
        }

    };
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        }, () => {
            this.setState({
                setslugError: false,
                settitleError: false,
                setdescriptionError: false,
                setpriceError: false,
            })
        });
    };
    render() {
        if (this.state.loading)
            return (
                <center>
                    <CircularProgress color="inherit" />
                </center>
            );
        return (
            <div>
                <Grid container spacing={12}>
                    {[
                        {
                            field: "slug",
                            label: "package slug",
                            placeholder: "package slug",
                            hint: this.state.setslugError ? "package slug" : "",
                        },
                        {
                            field: "title",
                            label: "package title",
                            placeholder: "package title",
                            hint: this.state.settitleError ? "package title" : "",
                        },
                        {
                            field: "description",
                            label: "package description",
                            placeholder: "package description",
                            hint: this.state.setdescriptionError ? "package description" : "",
                        },
                        {
                            field: "price",
                            label: "package price",
                            placeholder: "package price",
                            hint: this.state.setpriceError ? "package price" : "",
                        },
                    ].map((element, index) => (
                        <Grid item xs={10}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id={element.field}
                                label={element.label}
                                name={element.field}
                                placeholder={element.placeholder || element.label}
                                autoComplete={element.field}
                                value={this.state[element.field]}
                                onChange={this.handleChange}
                                multiline={
                                    element.field == "msgBody" || element.field == "name"
                                        ? true
                                        : false
                                }
                            />
                            {element.hint && <Typography color="error">{element.hint}</Typography>}
                        </Grid>
                    ))}

                </Grid>
                <br />
                <br />
                <Button variant="contained" disabled={this.state.setslugError || this.state.settitleError || this.state.setdescriptionError} color="primary" style={{ marginRight: "10px" }} onClick={() => {
                    this.UpdatePackage(this.props.data._id, this.props.close)
                }}> Update Package</Button>
                <Button variant="contained" onClick={this.props.close} color="inherit"> close  </Button>
            </div>
        );
    }
}
