import axios from "axios";

let baseUrl = process.env.NODE_ENV === "production" ? "https://api.myqatapp.com" : `http://37.148.211.46:5000`;

let getApiMode = () => {
  if (localStorage.getItem("api_mode")) {
    if (localStorage.getItem("api_mode") != "prod") {
      return '/api-test/';
    } else {
      return '/api/';
    }
  } else {
    try {
      axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("qat_token");
      axios.get(`${baseUrl}/api/data/app-settings`)
      .then ((response) => {
        localStorage.setItem("api_mode", response.data.payload.appMode);
      });
    } catch (error) {
      console.log (error);
    } finally {
      return '/api/';
    }
  }
};

class ApiRequests {
  static get = async (url) => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("qat_token");
    let res = await axios.get(`${baseUrl}${getApiMode()}${url}`);
    return res.data;
  };

  static post = async (url, body) => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("qat_token");
    let res = await axios.post(`${baseUrl}${getApiMode()}${url}`, body);
    return res.data;
  };

  static patch = async (url, body) => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("qat_token");
    let res = await axios.patch(`${baseUrl}${getApiMode()}${url}`, body);
    return res.data;
  };

  static delete = async (url) => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("qat_token");
    let res = await axios.delete(`${baseUrl}${getApiMode()}${url}`);
    return res.data;
  };
}

export default ApiRequests;
